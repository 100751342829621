#themeSettings {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 121px);
  margin: 0;
  background-color: #f5f6f8;
  overflow-y: scroll;

  .settingElements {
    list-style: none;
    padding-left: 0;
    margin: 0;
    cursor: pointer;
    li {
      color: #3B3B3B;
      display: flex;
      border-bottom: solid 1px #9b9b9b;
      padding: 20px 10px;
      justify-content: space-between;
    }

    .logo {
      display: flex;

      * {
        padding-right: 10px;
      }
    }

  }

  .themeSettingsButtons {
    padding-top: 20px;
    display: none;

    button {
      color: #3b3b3b;
      border: 1px solid #9b9b9b;
      width: 80%;
      margin-bottom: 15px;
    }
  }

}