.themeSettingsElement {

  .settingsComponent {
    width: 345px;
    display: flex;
    justify-content: center;
  }

  .textField {
    width: 90%;
    align-self: center;
    margin: 20px 0;
  }

  .color-picker-container {
    width: 90%;
    margin: 0 0 20px 0;
    text-align-last: left;
  }

  .picker-input-container > p {
    margin-top: 10px !important;
  }

  .logoSettings {
    width: 90%;

    .logoImportWrapper {
      min-height: 110px;
      border-radius: 5px;

      .logoImportButton {
        margin: 20px 10px;
      }
    }


  }

  .settingsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    margin: 0;
    background-color: #e7e7e7;
    overflow-y: auto;
  }

  .applyGlobalSettings {
    justify-content: center;
    margin-top: 10px;
  }

  .settingsSection {
    padding: 0 15px 10px 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #f5f6f8;
    -moz-box-shadow:    -7px 0 7px 4px #ccc;
    -webkit-box-shadow: -7px 0 7px 4px #ccc;
    box-shadow:         -7px 0 7px 4px #ccc;

    .fontDropdown {
      text-align: start;
      width: 100%;
      font-weight: 400;

      #font-picker-main, #font-picker-heading, #font-picker-footer {
        width: 95%;
        justify-content: center;
        display: flex;
      }
    }

    .settingsHeading {
      display: flex;
      align-self: start;
      margin-top: 20px;
    }

    .settingsDescription {
      margin: 0;
      color: #637381;
      display: flex;
      justify-content: center;
      text-align: start;
      font-size: smaller;
      text-transform: none;
    }

    .thumbnails {
      overflow: auto;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;

      .thumbnailsRow {
        display: flex;
      }

      .thumbnail {
        padding: 4px;
        margin: 1px;
      }

      .selected {
        border: 3px solid #1976d2;
        border-radius: 4px;
        padding: 1px;
      }
    }

    .imagePickerImage {
      display: block;
      width: 64px;
      height: auto;
      margin-right: auto;
      margin-left: auto;
    }

    .logoSizeScale {
      width: 90%;
      align-self: center;
      margin: 0 0 10px 0;
    }

    .logoPositionDropdown {
      margin: 30px 0;
    }
  }
}
