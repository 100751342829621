.wrapper {
  display: flex;
  height: calc(100vh - 80px);
 }

.elementBackButton {
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 12px 0;
  border-bottom: 1px solid #e7e7e7;
  align-content: center;
  background-color: #e7e7e7;
  width: 345px;
  z-index: 4;
  cursor: pointer;
}

.spacer {
  background: transparent;
  display: list-item;
  margin-bottom: 5px;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  z-index: 5;

  .popup {
    position: relative;
    top: 30%;
    margin-right: auto;
    margin-left: auto;
    width: fit-content;
    z-index: 6;
    padding: 10px 20px;
    height: fit-content;
    color: rgb(107, 107, 107);

    .popupElementsWrapper {
      display: flex;
      position: unset;
      flex-direction: column;

      .popupHeading {
        text-align: center;

        .closeButton {
          position: absolute;
          top: 10px;
          right: 10px;
          color: #434343;
          cursor: pointer;
        }
      }

      .popupBody {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        min-width: 80%;
        width: auto;
        align-self: center;
        justify-content: center;
        text-transform: initial;

        .descriptionSection {
          background-color: #fcf1cd;
          box-shadow: inset 0 3px 0 0 #eec200,inset 0 0 0 0 transparent,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
          padding: 24px;
          border: 0;
          border-radius: 0 0 3px 3px;
          transition: box-shadow .2s cubic-bezier(.64,0,.35,1);
          margin: 20px 0;
          text-align: start;
        }

        .description {
          text-align: start;
        }

        .screenshotsDescription {
          align-self: center;
          min-height: 150px;
        }
      }

      .popupFooter {
        text-align: right;
        border-top: 1px solid #e7e7e7;

        .cancelButton {
          color: #3b3b3b;
          border: 1px solid #9b9b9b;
          margin: 10px 5px;
        }

        .removeButton {
          background-color: #f10606;
          border: 1px solid #9b9b9b;
          margin: 10px 5px;
        }
      }
    }

  }

}

@import "editor_header";
@import "editor_sidebar";
@import "editor_preview";
@import "editor_theme_settings";
@import "editor_theme_style_settings";
