#elementPicker {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 131px);
  margin: 0;
  background-color: #FFFFFF;
  overflow-y: auto;

  .heading {
    margin: 0;
    padding: 10px 0;
    background-color: #e7e7e7;
    width: 100%;
    color: #707070;
    font-weight: lighter;
  }

  .elements {
    list-style: none;
    padding-left: 0;
    margin: 0;
    background-color: #f5f6f8;

    li {
      color: #3B3B3B;
      display: flex;
      align-items: center;
      border-bottom: solid 1px #d9d9d9;
      padding: 15px 10px 15px 20px;
      cursor: pointer;
      background: #f8f8f8;
      font-size: 19px;

      .elementIcon {
        display: flex;
        overflow: hidden;
        flex-shrink: 0;
        width: 2em;
        padding: .2em;
        margin-right: 20px;
      }
    }

    p {
      max-width: none;
      margin: 0;
    }
  }

}
