#header{
  margin: 0;
  height: 60px;
  display: flex;
  background-color: #212121;
  flex-direction: row;
  color: #f5f6f8;


  .theme{
    border-bottom: 1px solid #d5d6d8;
    display: flex;
    align-items: center;
  }

  .themeName{
    width: 345px;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .previewHeader{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d5d6d8;
    padding: 0 10px;
    z-index: 1;
    color: #f5f6f8;
  }

  .headerItem{
     .templateDropdown {
       background-color: #f5f6f8;
     }

    button {
      color: #212121;
      background-color: #f5f6f8;
      border-radius: 0;
    }

  }

  .headerButtons a {
    text-decoration: none;
    color: #212121;
  }

  .headerTabs{
    display: flex;
    justify-content: space-around;

    .headerTab {
      color: #f5f6f8;
      background-color: #212121;
    }
  }

  .paymentStatus{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .headerBackButton {
    margin: 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    cursor: pointer;


    .heading {
      width: 345px;
      border-bottom: 1px solid #d5d6d8;
      display: flex;
      align-items: center;

      .arrowBackIcon {
        position: absolute;
        left: 15px;
      }

      b {
        margin: auto;
        width: 75%;
        padding: 10px;
      }
    }
  }
}
