#elementOptions {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 131px);
  margin: 0;
  background-color: #e7e7e7;
  overflow-y: auto;

  .elementSettingsSection {
    background-color: #f5f6f8;
    margin: 10px 0;
    padding-bottom: 15px;
    -moz-box-shadow:    -7px 0 7px 4px #ccc;
    -webkit-box-shadow: -7px 0 7px 4px #ccc;
    box-shadow:         -7px 0 7px 4px #ccc;

    .sectionHeading {
      margin-left: 15px;
      margin-bottom: 15px;
      font-weight: 600;
      color: #212b35;
      text-align: start;
    }

    .elementDescription {
      display: flex;
      justify-content: center;
      text-align: start;
      color: #637381;
      font-size: 14px;
      padding: 15px 10px;
      margin-bottom: 15px;
      text-transform: initial;
    }

    .elementOption {
      margin: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .elementOptionDescription {
        width: 85%;
        text-align: start;
        margin-top: 5px;
        margin-left: 10px;
        font-size: small;
        font-weight: 400;
        color: #637381;
      }

      .color-picker-container {
        width: 100%;
        margin-bottom: 20px;
      }

      .color-picker-container p {
        margin-top: 0 !important;
      }
    }

    .elementOptionInput {
      width: 90%;
      text-align: start;

      .imageLabel {
        display: flex;
        text-align: center;
        margin-top: 20px;
        font-weight: 400;
      }

      .logoImportWrapper {
        min-height: 110px;
        border-radius: 5px;
        text-align: center;

        .imageImportButton {
          margin: 20px 10px;
        }
      }
    }
  }

  .local-font-toggle {
    width: 90%;
  }

  .styleSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border-bottom: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7;
  }

  .styleSectionHeading {
    display: flex;
    justify-content: center;
  }

  .styleSettings {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .infoBlock {
    color: #637381;
    font-size: 13px;
    line-height: 20px;
    padding: 0;
    margin-bottom: 15px;
  }

  .styleSettingsList {
    padding: 0;
    justify-content: center;

    li {
      display: flex;
      align-items: center;
      border-bottom: solid 1px #9b9b9b;
      padding: 15px;
      justify-content: space-evenly;
    }
  }

  .removeElementButton {
    color: #3b3b3b;
    border: 1px solid #3B3B3B;
    width: 180px;
    align-self: center;
    margin: 10px 0;
  }

}
