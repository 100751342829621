#previewWrapper {
  width: 100%;
  //z-index: 1;

  .preview{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f6f8;
    margin: 10px;
  }

}
