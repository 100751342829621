#sidebar {
  text-transform: capitalize;

  .sidebarOptions {
    width: 345px;
  }

  .sidebarElements {
    overflow-y: auto;
    height: calc(100vh - 180px);
    position: relative;
    list-style: none;
    padding-left: 0;
    margin: 0;
    background-color: #ffffff;

    li {
      color: #3B3B3B;
      display: flex;
      align-items: center;
      border-bottom: solid 1px #d9d9d9;
      padding: 15px 10px 15px 15px;
      background: #f8f8f8;
    }

    p {
      max-width: none;
      margin: 0;
      font-size: 19px;
    }
  }

  .elementIcon {
    display: flex;
    overflow: hidden;
    flex-shrink: 0;
    width: 2em;
    padding: .2em;
    margin-right: 20px;
  }

  .spacer {
    background: transparent;
    display: list-item;
    margin-bottom: 40px;
  }

  .alertWrapper {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 5;

    .alertInfo {
      justify-content: center;
      align-self: center;
      position: fixed;
      top: 40%;
      left: 35%;
      right: 35%;
      z-index: 6;
      padding: 10px;
      height: fit-content;
    }
  }

  .addContentElement {
    cursor: pointer;
    position: sticky;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #d9d9d9;
    border-top: solid 1px #d9d9d9;
    background: #f8f8f8;
    padding: 15px 10px 15px 15px;

    p {
      font-size: 19px;
      margin: 0;
    }
  }
}

@import "editor_sidebar_element_options";
@import "editor_sidebar_element_picker";
@import "editor_sidebar_theme_settings";
