#themeStyleSettings {
  border-right: 1px solid #d5d6d8;


  .body {
    width: 345px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    margin: 0;
    background-color: #f5f6f8;
    overflow-y: scroll;


    .theme {
      text-align: start;
      padding: 15px;
      margin: 20px 0;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);

      .themeTitle {
        margin-top: 0;
        font-weight: 600;
      }

      .themeImage {
        max-width: 345px;
        max-height: 500px;
        overflow: hidden;
        border: 1px solid #d3dbe2;
        border-radius: 3px;

        img {
          display: block;
          max-width: 100%;
          height: auto;
        }
      }

      .button {
        width: 100%;
        margin: 20px 0 10px 0;
      }
    }
  }

}
