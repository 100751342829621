.App {
  height: 100vh;
  text-align: center;
  background-color: #e7e7e7;
}

.wrapper {
  display: flex;
  height: calc(100vh - 80px);
}

.elementBackButton {
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 12px 0;
  border-bottom: 1px solid #e7e7e7;
  align-content: center;
  background-color: #e7e7e7;
  width: 345px;
  z-index: 4;
  cursor: pointer;
}

.spacer {
  background: transparent;
  display: list-item;
  margin-bottom: 5px;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
}
.popupWrapper .popup {
  position: relative;
  top: 30%;
  margin-right: auto;
  margin-left: auto;
  width: fit-content;
  z-index: 6;
  padding: 10px 20px;
  height: fit-content;
  color: rgb(107, 107, 107);
}
.popupWrapper .popup .popupElementsWrapper {
  display: flex;
  position: unset;
  flex-direction: column;
}
.popupWrapper .popup .popupElementsWrapper .popupHeading {
  text-align: center;
}
.popupWrapper .popup .popupElementsWrapper .popupHeading .closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #434343;
  cursor: pointer;
}
.popupWrapper .popup .popupElementsWrapper .popupBody {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-width: 80%;
  width: auto;
  align-self: center;
  justify-content: center;
  text-transform: initial;
}
.popupWrapper .popup .popupElementsWrapper .popupBody .descriptionSection {
  background-color: #fcf1cd;
  box-shadow: inset 0 3px 0 0 #eec200, inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  padding: 24px;
  border: 0;
  border-radius: 0 0 3px 3px;
  transition: box-shadow 0.2s cubic-bezier(0.64, 0, 0.35, 1);
  margin: 20px 0;
  text-align: start;
}
.popupWrapper .popup .popupElementsWrapper .popupBody .description {
  text-align: start;
}
.popupWrapper .popup .popupElementsWrapper .popupBody .screenshotsDescription {
  align-self: center;
  min-height: 150px;
}
.popupWrapper .popup .popupElementsWrapper .popupFooter {
  text-align: right;
  border-top: 1px solid #e7e7e7;
}
.popupWrapper .popup .popupElementsWrapper .popupFooter .cancelButton {
  color: #3b3b3b;
  border: 1px solid #9b9b9b;
  margin: 10px 5px;
}
.popupWrapper .popup .popupElementsWrapper .popupFooter .removeButton {
  background-color: #f10606;
  border: 1px solid #9b9b9b;
  margin: 10px 5px;
}

#header {
  margin: 0;
  height: 60px;
  display: flex;
  background-color: #212121;
  flex-direction: row;
  color: #f5f6f8;
}
#header .theme {
  border-bottom: 1px solid #d5d6d8;
  display: flex;
  align-items: center;
}
#header .themeName {
  width: 345px;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}
#header .previewHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d5d6d8;
  padding: 0 10px;
  z-index: 1;
  color: #f5f6f8;
}
#header .headerItem .templateDropdown {
  background-color: #f5f6f8;
}
#header .headerItem button {
  color: #212121;
  background-color: #f5f6f8;
  border-radius: 0;
}
#header .headerButtons a {
  text-decoration: none;
  color: #212121;
}
#header .headerTabs {
  display: flex;
  justify-content: space-around;
}
#header .headerTabs .headerTab {
  color: #f5f6f8;
  background-color: #212121;
}
#header .paymentStatus {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
#header .headerBackButton {
  margin: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
#header .headerBackButton .heading {
  width: 345px;
  border-bottom: 1px solid #d5d6d8;
  display: flex;
  align-items: center;
}
#header .headerBackButton .heading .arrowBackIcon {
  position: absolute;
  left: 15px;
}
#header .headerBackButton .heading b {
  margin: auto;
  width: 75%;
  padding: 10px;
}

#sidebar {
  text-transform: capitalize;
}
#sidebar .sidebarOptions {
  width: 345px;
}
#sidebar .sidebarElements {
  overflow-y: auto;
  height: calc(100vh - 180px);
  position: relative;
  list-style: none;
  padding-left: 0;
  margin: 0;
  background-color: #ffffff;
}
#sidebar .sidebarElements li {
  color: #3B3B3B;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #d9d9d9;
  padding: 15px 10px 15px 15px;
  background: #f8f8f8;
}
#sidebar .sidebarElements p {
  max-width: none;
  margin: 0;
  font-size: 19px;
}
#sidebar .elementIcon {
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  padding: 0.2em;
  margin-right: 20px;
}
#sidebar .spacer {
  background: transparent;
  display: list-item;
  margin-bottom: 40px;
}
#sidebar .alertWrapper {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
}
#sidebar .alertWrapper .alertInfo {
  justify-content: center;
  align-self: center;
  position: fixed;
  top: 40%;
  left: 35%;
  right: 35%;
  z-index: 6;
  padding: 10px;
  height: fit-content;
}
#sidebar .addContentElement {
  cursor: pointer;
  position: sticky;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #d9d9d9;
  border-top: solid 1px #d9d9d9;
  background: #f8f8f8;
  padding: 15px 10px 15px 15px;
}
#sidebar .addContentElement p {
  font-size: 19px;
  margin: 0;
}

#elementOptions {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 131px);
  margin: 0;
  background-color: #e7e7e7;
  overflow-y: auto;
}
#elementOptions .elementSettingsSection {
  background-color: #f5f6f8;
  margin: 10px 0;
  padding-bottom: 15px;
  -moz-box-shadow: -7px 0 7px 4px #ccc;
  -webkit-box-shadow: -7px 0 7px 4px #ccc;
  box-shadow: -7px 0 7px 4px #ccc;
}
#elementOptions .elementSettingsSection .sectionHeading {
  margin-left: 15px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #212b35;
  text-align: start;
}
#elementOptions .elementSettingsSection .elementDescription {
  display: flex;
  justify-content: center;
  text-align: start;
  color: #637381;
  font-size: 14px;
  padding: 15px 10px;
  margin-bottom: 15px;
  text-transform: initial;
}
#elementOptions .elementSettingsSection .elementOption {
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#elementOptions .elementSettingsSection .elementOption .elementOptionDescription {
  width: 85%;
  text-align: start;
  margin-top: 5px;
  margin-left: 10px;
  font-size: small;
  font-weight: 400;
  color: #637381;
}
#elementOptions .elementSettingsSection .elementOption .color-picker-container {
  width: 100%;
  margin-bottom: 20px;
}
#elementOptions .elementSettingsSection .elementOption .color-picker-container p {
  margin-top: 0 !important;
}
#elementOptions .elementSettingsSection .elementOptionInput {
  width: 90%;
  text-align: start;
}
#elementOptions .elementSettingsSection .elementOptionInput .imageLabel {
  display: flex;
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
}
#elementOptions .elementSettingsSection .elementOptionInput .logoImportWrapper {
  min-height: 110px;
  border-radius: 5px;
  text-align: center;
}
#elementOptions .elementSettingsSection .elementOptionInput .logoImportWrapper .imageImportButton {
  margin: 20px 10px;
}
#elementOptions .local-font-toggle {
  width: 90%;
}
#elementOptions .styleSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
}
#elementOptions .styleSectionHeading {
  display: flex;
  justify-content: center;
}
#elementOptions .styleSettings {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#elementOptions .infoBlock {
  color: #637381;
  font-size: 13px;
  line-height: 20px;
  padding: 0;
  margin-bottom: 15px;
}
#elementOptions .styleSettingsList {
  padding: 0;
  justify-content: center;
}
#elementOptions .styleSettingsList li {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #9b9b9b;
  padding: 15px;
  justify-content: space-evenly;
}
#elementOptions .removeElementButton {
  color: #3b3b3b;
  border: 1px solid #3B3B3B;
  width: 180px;
  align-self: center;
  margin: 10px 0;
}

#elementPicker {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 131px);
  margin: 0;
  background-color: #FFFFFF;
  overflow-y: auto;
}
#elementPicker .heading {
  margin: 0;
  padding: 10px 0;
  background-color: #e7e7e7;
  width: 100%;
  color: #707070;
  font-weight: lighter;
}
#elementPicker .elements {
  list-style: none;
  padding-left: 0;
  margin: 0;
  background-color: #f5f6f8;
}
#elementPicker .elements li {
  color: #3B3B3B;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #d9d9d9;
  padding: 15px 10px 15px 20px;
  cursor: pointer;
  background: #f8f8f8;
  font-size: 19px;
}
#elementPicker .elements li .elementIcon {
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  padding: 0.2em;
  margin-right: 20px;
}
#elementPicker .elements p {
  max-width: none;
  margin: 0;
}

#themeSettings {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 121px);
  margin: 0;
  background-color: #f5f6f8;
  overflow-y: scroll;
}
#themeSettings .settingElements {
  list-style: none;
  padding-left: 0;
  margin: 0;
  cursor: pointer;
}
#themeSettings .settingElements li {
  color: #3B3B3B;
  display: flex;
  border-bottom: solid 1px #9b9b9b;
  padding: 20px 10px;
  justify-content: space-between;
}
#themeSettings .settingElements .logo {
  display: flex;
}
#themeSettings .settingElements .logo * {
  padding-right: 10px;
}
#themeSettings .themeSettingsButtons {
  padding-top: 20px;
  display: none;
}
#themeSettings .themeSettingsButtons button {
  color: #3b3b3b;
  border: 1px solid #9b9b9b;
  width: 80%;
  margin-bottom: 15px;
}

#previewWrapper {
  width: 100%;
}
#previewWrapper .preview {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f6f8;
  margin: 10px;
}

.themeSettingsElement .settingsComponent {
  width: 345px;
  display: flex;
  justify-content: center;
}
.themeSettingsElement .textField {
  width: 90%;
  align-self: center;
  margin: 20px 0;
}
.themeSettingsElement .color-picker-container {
  width: 90%;
  margin: 0 0 20px 0;
  text-align-last: left;
}
.themeSettingsElement .picker-input-container > p {
  margin-top: 10px !important;
}
.themeSettingsElement .logoSettings {
  width: 90%;
}
.themeSettingsElement .logoSettings .logoImportWrapper {
  min-height: 110px;
  border-radius: 5px;
}
.themeSettingsElement .logoSettings .logoImportWrapper .logoImportButton {
  margin: 20px 10px;
}
.themeSettingsElement .settingsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  margin: 0;
  background-color: #e7e7e7;
  overflow-y: auto;
}
.themeSettingsElement .applyGlobalSettings {
  justify-content: center;
  margin-top: 10px;
}
.themeSettingsElement .settingsSection {
  padding: 0 15px 10px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #f5f6f8;
  -moz-box-shadow: -7px 0 7px 4px #ccc;
  -webkit-box-shadow: -7px 0 7px 4px #ccc;
  box-shadow: -7px 0 7px 4px #ccc;
}
.themeSettingsElement .settingsSection .fontDropdown {
  text-align: start;
  width: 100%;
  font-weight: 400;
}
.themeSettingsElement .settingsSection .fontDropdown #font-picker-main, .themeSettingsElement .settingsSection .fontDropdown #font-picker-heading, .themeSettingsElement .settingsSection .fontDropdown #font-picker-footer {
  width: 95%;
  justify-content: center;
  display: flex;
}
.themeSettingsElement .settingsSection .settingsHeading {
  display: flex;
  align-self: start;
  margin-top: 20px;
}
.themeSettingsElement .settingsSection .settingsDescription {
  margin: 0;
  color: #637381;
  display: flex;
  justify-content: center;
  text-align: start;
  font-size: smaller;
  text-transform: none;
}
.themeSettingsElement .settingsSection .thumbnails {
  overflow: auto;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.themeSettingsElement .settingsSection .thumbnails .thumbnailsRow {
  display: flex;
}
.themeSettingsElement .settingsSection .thumbnails .thumbnail {
  padding: 4px;
  margin: 1px;
}
.themeSettingsElement .settingsSection .thumbnails .selected {
  border: 3px solid #1976d2;
  border-radius: 4px;
  padding: 1px;
}
.themeSettingsElement .settingsSection .imagePickerImage {
  display: block;
  width: 64px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}
.themeSettingsElement .settingsSection .logoSizeScale {
  width: 90%;
  align-self: center;
  margin: 0 0 10px 0;
}
.themeSettingsElement .settingsSection .logoPositionDropdown {
  margin: 30px 0;
}

#themeStyleSettings {
  border-right: 1px solid #d5d6d8;
}
#themeStyleSettings .body {
  width: 345px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  margin: 0;
  background-color: #f5f6f8;
  overflow-y: scroll;
}
#themeStyleSettings .body .theme {
  text-align: start;
  padding: 15px;
  margin: 20px 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}
#themeStyleSettings .body .theme .themeTitle {
  margin-top: 0;
  font-weight: 600;
}
#themeStyleSettings .body .theme .themeImage {
  max-width: 345px;
  max-height: 500px;
  overflow: hidden;
  border: 1px solid #d3dbe2;
  border-radius: 3px;
}
#themeStyleSettings .body .theme .themeImage img {
  display: block;
  max-width: 100%;
  height: auto;
}
#themeStyleSettings .body .theme .button {
  width: 100%;
  margin: 20px 0 10px 0;
}

